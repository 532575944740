var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"control-panel",on:{"click":function($event){$event.stopPropagation();_vm.control.curWidget = null}}},[_c('div',{staticClass:"phone-page",style:({
      height: _vm.iframeHeight + 'px',
    })},[_c('iframe',{ref:"iframe",staticClass:"page-iframe",style:({
        height: _vm.iframeHeight + 'px',
        pointerEvents: _vm.control.dragstatus ? 'none' : 'auto',
      }),attrs:{"frameborder":"no","src":_vm.iframeUrl},on:{"load":_vm.load}}),_c('div',{staticClass:"phone-page-player",style:({
        height: _vm.iframeHeight + 'px',
        zIndex: _vm.control.dragstatus ? '20' : '1',
      }),on:{"dragover":_vm.layerMove,"drop":_vm.drop,"dragleave":_vm.dragleave}},_vm._l((_vm.widgetInfoList),function(item){return _c('ControlModelShape',{key:item.id,attrs:{"data":item},on:{"changeCurrWidget":_vm.changeCurrWidget}},[_c('div',{ref:"layerWidget",refInFor:true,staticClass:"page-layer-widget",style:({ height: item.height + 'px' }),attrs:{"type":"widget","id":item.id}})])}),1)]),_c('div',{staticClass:"pageSetting",on:{"click":function($event){$event.stopPropagation();_vm.control.curWidget = null}}},[_c('i',{staticClass:"el-icon-setting"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }