<template>
  <div class="p10">
    <configure-item label="全局背景色">
      <config-color-picker v-model="config.backgroundColor"></config-color-picker>
    </configure-item>
    <schema-string
      label="页面标题"
      v-model="config.pageTitle"
      :options="{ maxLength: 10, placeholder: '请输入内容 最多10个字' }"
    ></schema-string>
    <schema-string
      label="分享标题"
      v-model="config.shareTitle"
      :options="{ maxLength: 10, placeholder: '请输入内容 最多10个字' }"
    ></schema-string>
    <schema-string
      label="分享内容"
      v-model="config.shareContent"
      :options="{ maxLength: 20, placeholder: '请输入内容 最多20个字' }"
    ></schema-string>
    <schema-upload label="分享小图" v-model="config.shareImg"></schema-upload>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PageConfig",
  computed: {
    ...mapGetters(["project", "pageConfig"]),
  },
  watch: {
    pageConfig: {
      immediate: true,
      deep: true,
      handler(newVal) {
        console.log("====================================");
        console.log(newVal);
        console.log("====================================");
        if (newVal) {
          this.config = newVal;
        }
      },
    },
  },
  data() {
    return {
      config: {
        backgroundColor: "",
        pageTitle: "",
        shareTitle: "",
        shareContent: "",
        shareImg: "",
      },
    };
  },
  mounted() {},
};
</script>
